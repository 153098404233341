import React from 'react';
import './style.css';
import SideNav from '../Navbar/Navbar.js';

import header1 from '../../img/logo.svg';



function Header () {
    return (
        <div className="container-fluid header shadow-sm live-gradient">
            {/* <button id="trigger" className="fixed drop text-icon btn" onclick="show()">≡</button> */}
        <div className="row">
            <div className="col nav">
                <SideNav />
            </div>
            <div className="col-9">
                <h3 className="header-text pt-5 times">skilled craftsmanship delivered</h3>
                </div>
            <div className="col-2">

                <img className="headerpic mt-4" src={header1} alt="HandyByNature" />
            </div>
        </div>
        </div>
    )
}

export default Header;