import React, { Component } from 'react';
import Footer from '../components/Footer/Footer';

class Contact extends Component {

  render() {

    return (

      <div className="appcontent contactbkg pt-5">
        

        <div className="container pt-5">
          <div className="card-glass shadow border2">
          <div className='col-12 pt-5 mt-5'>
              
                <h3 className="col center white pt-5 mt-5">For a Free Estimate!</h3>
            
             <h4 className='col-12 text-warning text-justify pl-5 pr-5 text-center'>
               Call Us Today:<br />
               
               <a href="tel:+6786534159">
                 <p className="white fw-bold mt-2">678-653-4159</p>
                 </a>
               </h4>
            <h5 className='col-12 text-warning center text-center'>
              Email Immediately @:<br />
            <small className="center btn btn-light shadow mt-3">
            <a href="mailto:info@handybynature.com"> <p className="pt-3 text-dark">info@handybynature.com</p></a>
            </small>
          </h5>
          
          </div>
            <div className="col-12 text-center pt-4 pb-4 mb-3">
              <br></br>
              <div className="center">
              <form action="mailto:tobie@handybynature.com" method="post" enctype="text/plain">
                <label className="text-white text-heavy">
                  Name:
              <input className="field" type="text" name="name" />
                </label>
                <br></br>
                <label className="text-white text-heavy">
                  Phone:
              <input className="field" type="text" name="phone" />
                </label>
                <br></br>
                <label className="text-white text-heavy">
                  Email:
              <input className="field" type="text" name="email" />
                </label>
                <br></br>
                <label className="text-white text-heavy">
                  Comment:
              <br></br>
                  <textarea className="field" rows="4" cols="27" name="comment" />
                </label>
                <br></br>
                <input className="bg-dark text-warning font-weight-bold shadow border2" type="submit" value="SEND" />
              </form>
              </div>
            </div>
            
            <div className=""></div>
          </div>
          <Footer />        
          </div>
         
      </div>



    );
  }

}

export default Contact;

