import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import ImagesGallery from '../components/ImageGallery/ImagesGallery';
// import CAROUSEL_1 from '../components/carousel';
import Footer from '../components/Footer/Footer';

// import trellis from '../img/trellis.jpg';
// import workshop1 from '../img/shop1.jpg';
// import door1 from '../img/door1.jpg';
// import door2 from '../img/door2.jpg';
// import door3 from '../img/door3.jpg';


class Gallery extends Component {

  render() {
  
    return (
      <div className='appcontent bkg'>
        {/* <div className='fixed ml-3 mt-2'>
              <Link className='btn btn-light border text-dark shadow-sm contact' to='/contact'>Contact</Link>
            </div> */}
      <div className='glass'></div>
      
        <div className='container'>
          
           <div className="mt-5 pt-5">
            <ImagesGallery />
            </div>
           
              
                   
          <Footer />
        </div>
        
      </div>
    );
  }

}



export default Gallery;

