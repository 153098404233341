import React, { Component } from 'react';

import { Link } from "react-router-dom";

// import CAROUSEL_1 from '../components/carousel';
import ImagesSlides from '../components/ImageGallery/ImagesSlides';

import Footer from '../components/Footer/Footer';

import workshop1 from "../img/shop1.jpg";

class Home extends Component {

  render() {
  
    return (
      <div className="appcontent bkg">
        {/* <div className="fixed ml-3 mt-2">
              <Link className="btn btn-light border text-dark shadow-sm contact" to="/contact">Contact</Link>
            </div> */}
      <div className="glass"></div>
      <div className='slideshow w-100 toplevel relative'>
              
              <ImagesSlides />
            </div>
            
        <div className="container pt-5">
        
          <div className='row mt-5 pt-5'>
          
            <h5 className='col-12 text-justify pl-5 pr-5 white text-center'>Our mission is to bring quality carpentry repair and design work to our friends and neighbors at competitive prices! We do this with the utmost Honesty and Integrity because that’s how it should be!
            <br />
            <div className="mt-4 btn btn-dark shadow border white">
            <Link className="gold" to="/contact">Contact Us for a Free Estimate!</Link> 
            </div>
            </h5>
           
            <div className="col-12">
                <h2 className="center white fw-bold times header-text2 pt-4">skilled craftsmanship delivered</h2>
                </div>

           <img className='row picfit center' alt='Workshop' src={workshop1} />
           
           
          </div>
          
          <Footer />
        </div>
        
      </div>
    );
  }

}



export default Home;

