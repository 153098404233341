import React,{useState} from 'react'



const UploadForm = () => {
    const[file,setFile]=useState(null);
    const[error,setError]=useState(null);
    const types=['image/png','image/jpg'];

    const changedHandler=(e)=>{
        let selected=e.target.files[0];
        if(selected && types.includes(selected.type)){
            setFile(selected);
            setError('');
        }else{
            setFile(null);
            setError('filetypes accepted are .png and .jpg');
        }
        }

            
    return (
        <div className="container mt-5 mb-5 pt-5">
        <form className="row mt-5">
            <label className="col">
            <input type="file"  onChange={changedHandler} />
            </label>
            <div>
            { error && <div className="error"> {error} </div> }
            { file && <div className="file"> {file.name} </div> }
            </div>
        </form>
        </div>
    )
}

export default UploadForm;