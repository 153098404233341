import React from "react";
import "./style.css";
import "../../App.css"
import Home from '../../pages/home';
import Manage from '../../pages/manage';
import Contact from '../../pages/contact';
import Gallery from '../../pages/gallery';



import {
  Switch,
  Route
} from "react-router-dom";
import ImagesGallery from "../ImageGallery/ImagesGallery";



function Core() {
  return (

    <Switch>
      <Route path="/" exact component={Home} />

      <Route path="/manage" component={Manage} />

      <Route path="/contact" component={Contact} />

      <Route path="/gallery" component={Gallery} />
    
      
    </Switch>

  );
};

export default Core;