import React from 'react';
// import { icons } from 'react-icons';

import {} from 'react-icons';
// import 'react-sidebar-ui/dist/index.css';

import { Link } from "react-router-dom";

import './style.css';

import logo1 from '../../img/favicon.png';


const SideNav = () => {
  
  return (
    
    <>
    <div className="menubutton">
      <div>≡</div>
      <span className="shadow menu">
            <div className='logotop'>

              <img
                className="icon"
                src={logo1}
                alt='logo' />
            </div>
            <h2 className='logotext'>Handy By Nature</h2>
            
            <div bgcolor='black'>
              <div><i className="fas fa-home blackbkg" /></div>
              <Link className="gold" to="/">Home</Link>
            </div>
            <div bgcolor='black'>
              <div><i className="fas fa-home blackbkg" /></div>
              <Link className="gold" to="/gallery">Gallery</Link>
            </div>
            {/* <div bgcolor='black'>
              <i className="fas fa-info blackbkg" />
              <a className="" href="/about">About</a>
            </div> */}
            <div bgcolor='black'>
              <i className="far fa-address-book blackbkg" />
              <Link className="gold" to="/contact">Contact</Link>
            </div>
      
      </span>
      </div>
    
    {/* <button id="trigger" className="fixed drop text-icon btn">≡</button>
      <div bgcolor='black' className="sidenav">
        
        <div className='overcover'>
        
          <div id="covered" className='menu'>
            <div className='logotop'>

              <img
                className="icon"
                src={logo1}
                alt='logo' />
            </div>
            <h2 className='logotext'>Handy By Nature</h2>
            
            <div bgcolor='black'>
              <div><i className="fas fa-home blackbkg" /></div>
              <Link className="" to="/">Home</Link>
            </div>
            <div bgcolor='black'>
              <Icon><i className="fas fa-info blackbkg" /></Icon>
              <a className="" href="/about">About</a>
            </div>
            <div bgcolor='black'>
              <div><i className="far fa-address-book blackbkg" /></div>
              <Link className="" to="/contact">Contact</Link>
            </div>
      
          </div>
        </div>
      </div> */}
     </>
    
  )
  
};

export default SideNav;