import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import "./image-gallery.scss";
import ImageGallery from "react-image-gallery";
const ImagesSlides = () => {
  const [images, setImages] = React.useState(null)
  
  React.useEffect(() => {
    
    let shouldCancel = false
    const call = async () => {
      const response = await axios.get(
        'https://gphotosrv.somehow.win/Y1y32Fg62K2icz1dA'
      );
      if (!shouldCancel && response.data && response.data.length > 0) {
        setImages(response.data.map(url => ({
          original: `${url}=w1024`,
          thumbnail: `${url}=w100`
        })))
      }
    }
    call()
    return () => shouldCancel = true
  }, [])
  return images ? <ImageGallery items={images} className="col-auto" /> : null
}
export default ImagesSlides