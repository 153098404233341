import React, { Component } from 'react';
import '../App.css'

import Footer from '../components/Footer/Footer';
import UploadForm from '../components/UploadForm';
// import aboutpic from "../../img/coding.jpg";

class Manage extends Component {

  render() {

    return (

      <div className="appcontent bkg">

        <div className="container appcontent tkbkg">
        <UploadForm />
            
          </div>
          <Footer />
        </div>


    );
  }

}



export default Manage;

