import React from 'react';
import './App.css';
import Wrapper from './components/Wrapper/Wrapper';
import Header from './components/Header/Header';
import Main from './components/Main/Main';

import HttpsRedirect from 'react-https-redirect';


import { BrowserRouter as Router } from "react-router-dom";



function App() {
  return (
    <div className="App">
      <div className="App-head">
        <HttpsRedirect>
          <Router>
            <Wrapper>
                <Header />
                <div id="maincontent">
                  <Main />
                </div>
                
              
            </Wrapper>
          </Router>
        </HttpsRedirect>
      </div>

    </div>
  );
}

export default App;
